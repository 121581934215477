export const weekroutes = [
    {
        path: '/weekpage_1.1',
        name: 'weekpage_1.1',
        component: () => import('../views/PageContent/WeekContent/week1.1')
    },
    {
        path: '/weekpage_1.2',
        name: 'weekpage_1.2',
        component: () => import('../views/PageContent/WeekContent/week1.2')
    },
    {
        path: '/weekpage_1.3',
        name: 'weekpage_1.3',
        component: () => import('../views/PageContent/WeekContent/week1.3')
    },
    {
        path: '/weekpage_1.4',
        name: 'weekpage_1.4',
        component: () => import('../views/PageContent/WeekContent/week1.4')
    },
    {
        path: '/weekpage_1.5',
        name: 'weekpage_1.5',
        component: () => import('../views/PageContent/WeekContent/week1.5')
    },
    {
        path: '/weekpage_1.6',
        name: 'weekpage_1.6',
        component: () => import('../views/PageContent/WeekContent/week1.6')
    },
    {
        path: '/weekpage_1.7',
        name: 'weekpage_1.7',
        component: () => import('../views/PageContent/WeekContent/week1.7')
    },
    {
        path: '/weekpage_1.8',
        name: 'weekpage_1.8',
        component: () => import('../views/PageContent/WeekContent/week1.8')
    },
    {
        path: '/weekpage_1.9',
        name: 'weekpage_1.9',
        component: () => import('../views/PageContent/WeekContent/week1.9')
    },
    {
        path: '/weekpage_1.10',
        name: 'weekpage_1.10',
        component: () => import('../views/PageContent/WeekContent/week1.10')
    },
    {
        path: '/weekpage_2.1',
        name: 'weekpage_2.1',
        component: () => import('../views/PageContent/WeekContent/week2.1')
    },
    {
        path: '/weekpage_2.2',
        name: 'weekpage_2.2',
        component: () => import('../views/PageContent/WeekContent/week2.2')
    },
    {
        path: '/weekpage_2.3',
        name: 'weekpage_2.3',
        component: () => import('../views/PageContent/WeekContent/week2.3')
    }
]